import Image from "next/image";
import { Header } from "@components/Header";
import { Main } from "./Main";
import { ExtendedFooterSection } from "@components/ExtendedFooterSection";
import { SecondaryMenu } from "@components/SecondaryMenu";
import { DevGuidelines } from "@components/dev-guidelines";
import { Footer } from "@components/Footer";

import { useScrollClassDirection } from "@hooks/useScrollClassDirection";
import { styled } from "@theme";

export function MainLayout({
  children,
  variant,
  headerCategoryName,
  headerCategorySlug,
  animateMenuOnScroll = true,
  footer,
}) {
  useScrollClassDirection({
    selector: animateMenuOnScroll ? ".translatable-up" : "_",
  });
  useScrollClassDirection({
    selector: animateMenuOnScroll ? ".translatable-down" : "_",
  });

  return (
    <MainLayoutContainer
      variant={variant}
      className={animateMenuOnScroll ? "animateMenuOnScroll" : ""}
    >
      <TopScrollAnimatedItem
        variant={variant}
        className={`translatable-up ${variant}`}
      >
        <Header
          variant={variant}
          headerCategoryName={headerCategoryName}
          headerCategorySlug={headerCategorySlug}
        />
      </TopScrollAnimatedItem>

      {variant === "spirograph" && (
        <>
          <BackgroundImage />
          <TopGradient />
        </>
      )}

      <Main variant={variant}>{children}</Main>

      {footer?.extended_footer?.content && (
        <ExtendedFooterSection {...footer} />
      )}
      {footer && (
        <FooterContainer>
          <Footer />
        </FooterContainer>
      )}

      <BottomScrollAnimatedItem
        variant={variant}
        className={`translatable-down ${variant}`}
      >
        <SecondaryMenu variant={variant} />
      </BottomScrollAnimatedItem>
      {/* <DevGuidelines /> */}

      <style jsx global>{`
        *::-webkit-scrollbar {
          width: 2px;
          height: 2px;
          background-color: ${variant === "dark" ? "#000" : "#fff"};
        }

        *::-webkit-scrollbar-thumb {
          border-radius: 30px;
          background-color: ${variant === "dark" ? "#646464" : "#646464"};
        }
        * {
          scrollbar-width: thin;
          scrollbar-color: ${variant === "dark" ? "#000" : "transparent"};
        }
      `}</style>
    </MainLayoutContainer>
  );
}

const MainLayoutContainer = styled("div", {
  minHeight: "100vh",
  minWidth: "100%",
  maxWidth: "100vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",

  "&.animateMenuOnScroll": {
    paddingTop: "var(--header-height)",
  },

  ".next-image-in-the-background": {
    zIndex: "0",

    "&.mobile": {
      display: "block !important",
      "@4": {
        display: "none !important",
      },
    },
    "&.desktop": {
      display: "none !important",
      "@4": {
        display: "block !important",
      },
    },
  },

  variants: {
    variant: {
      dark: {
        backgroundColor: "#000",
      },
      light: {
        backgroundColor: "#fff",
      },
      spirograph: {
        backgroundImage: "linear-gradient(to right, #171f41, #24338a)",
      },
    },
  },

  // Don't use overflow hidden or it will break the sticky header
  //overflow: hidden,
});

const TopGradient = styled("div", {
  backgroundImage: "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0))",
  position: "absolute",
  left: "0px",
  right: "0px",
  top: "0px",
  width: "100%",
  height: "33vh",
});

const FooterContainer = styled("div", {
  minWidth: "100%",
  maxWidth: "100vw",
  position: "absolute",
  bottom: "var(--secondary-menu-height)",
  "@4": {
    bottom: "0px",
  },
});

const TopScrollAnimatedItem = styled("div", {
  display: "flex",
  flexDirection: "column",
  transition: "all 0.4s",
  width: "100%",

  position: "fixed",
  top: "0px",
  left: "0px",
  right: "0px",
  overflow: "hidden",
  zIndex: 999,

  "&.scroll-up.translatable-up.dark": {
    backgroundColor: "#000",
  },
  "&.scroll-up.translatable-up.dark.add-shadow": {
    boxShadow: "0 2px 4px #646464",
  },
  "&.scroll-up.translatable-up.dark.remove-shadow": {
    boxShadow: "none",
  },
  "&.scroll-up.translatable-up.light": {
    backgroundColor: "white",
  },
  "&.scroll-up.translatable-up.light.add-shadow": {
    boxShadow: "0 0 5px 0px rgb(0 0 0 / 30%)",
  },
  "&.scroll-up.translatable-up.light.remove-shadow": {
    boxShadow: "none",
  },
  "&.scroll-down.translatable-up": {
    transform: "translate3d(0, -100%, 0)",
    opacity: 0,
  },
  "&.scroll-up.translatable-up": {
    transform: "translate3d(0, 0, 0)",
    opacity: 1,
  },

  variants: {
    variant: {
      spirograph: {
        position: "absolute",
      },
    },
  },
});

const BottomScrollAnimatedItem = styled("div", {
  display: "flex",
  justifyContent: "center",
  transition: "all 0.4s",
  width: "100%",

  position: "fixed",
  bottom: "0px",
  left: "0px",
  right: "0px",
  overflow: "hidden",
  zIndex: 999,
  backgroundColor: "#fff",

  "&.scroll-up.translatable-down.dark": {
    backgroundColor: "white",
    boxShadow: "0 0 20px 0px rgb(65, 65, 65)",
    boxShadow: "0px -1px 5px rgb(0 0 0 / 30%)",
  },
  "&.scroll-up.translatable-down.light": {
    backgroundColor: "white",
    boxShadow: "0px -1px 5px rgb(0 0 0 / 30%)",
  },
  "&.scroll-down.translatable-down": {
    transform: "translate3d(0, 100%, 0)",
    opacity: 0,
  },
  "&.scroll-up.translatable-down": {
    transform: "translate3d(0, 0, 0)",
    opacity: 1,
  },

  "@media (min-width: 769px)": {
    display: "none",
  },

  variants: {
    variant: {
      spirograph: {
        position: "absolute",
      },
    },
  },
});

function BackgroundImage() {
  return (
    <>
      <Image
        className="next-image-in-the-background mobile"
        alt="" // the image here is only decorative - we don't need to announce it to screen readers
        src={`/TONE23_Wrapping-Paper_blue_320x568_75.jpeg`}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
      <Image
        className="next-image-in-the-background desktop"
        alt="" // the image here is only decorative - we don't need to announce it to screen readers
        src={`/TONE23_Wrapping-Paper_blue_2560x1440_75.jpeg`}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
    </>
  );
}
