import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14.4 14.4" {...props}>
      <g data-name="Group 37">
        <path
          data-name="Path 35"
          d="M7.206 9.601a2.422 2.422 0 112.395-2.432 2.407 2.407 0 01-2.395 2.432m.177-6.187a3.772 3.772 0 103.554 3.96 3.743 3.743 0 00-3.554-3.96m3.713-1.034a.9.9 0 00-.906.871.919.919 0 00.9.916.908.908 0 00.883-.9.892.892 0 00-.881-.892"
          fill="#FFFFFF"
        />
        <path
          data-name="Path 36"
          d="M7.206 9.602a2.422 2.422 0 112.395-2.433 2.408 2.408 0 01-2.395 2.433m.177-6.187a3.771 3.771 0 103.559 3.958 3.742 3.742 0 00-3.554-3.959"
          fill="#FFFFFF"
        />
        <path
          data-name="Path 37"
          d="M11.096 2.38a.892.892 0 01.881.892.908.908 0 01-.883.9.919.919 0 01-.9-.916.9.9 0 01.906-.871"
          fill="#FFFFFF"
        />
        <path
          data-name="Path 38"
          d="M4.189 1.319a2.873 2.873 0 00-2.87 2.87v6.022a2.873 2.873 0 002.87 2.87h6.022a2.874 2.874 0 002.871-2.87V4.189a2.874 2.874 0 00-2.871-2.87zM10.211 14.4H4.189A4.194 4.194 0 010 10.211V4.189A4.194 4.194 0 014.189 0h6.022A4.194 4.194 0 0114.4 4.189v6.022a4.194 4.194 0 01-4.189 4.189"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
