import { render as richText } from "storyblok-rich-text-react-renderer";

import SbEditable from "storyblok-react";

import { InnerContainer } from "@components/layouts/InnerContainer";

import { styled } from "@theme";

export function ExtendedFooterSection({
  extended_footer,
  ...viewingGlobalFooterSection
}) {
  // We set Storyblok to return:
  // 'viewingGlobalFooterSection' if the 'Editor person' is viewing the global footer section
  // 'extended_footer' if the 'Editor person' is viewing the footer section used in any local page

  const resolvedFooter = extended_footer
    ? extended_footer.content.items
    : viewingGlobalFooterSection.items;

  const content =
    resolvedFooter?.find((item) => item.component === "footer") || {};

  const { about, contact, services } = content;

  return (
    <SbEditable content={content}>
      <ExtendedFooterContainer>
        <InnerContainer>
          <Grid>
            <GridItem className="about">
              <Heading>About</Heading>
              <UnorderedList>{richText(about)}</UnorderedList>
            </GridItem>
            <GridItem className="contact">
              <Heading>Contact</Heading>
              <UnorderedList>{richText(contact)}</UnorderedList>
            </GridItem>
            <GridItem className="services">
              <Heading>Services</Heading>
              <UnorderedList>{richText(services)}</UnorderedList>
            </GridItem>
          </Grid>
        </InnerContainer>
      </ExtendedFooterContainer>
    </SbEditable>
  );
}

const ExtendedFooterContainer = styled("div", {
  display: "flex",
  width: "100vw",
  backgroundColor: "var(--color-f9f9f9)",
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "calc(var(--footer-height) + var(--secondary-menu-height))",
  
  "@4": {
    paddingBottom: "var(--footer-height)",
  }
});

const Grid = styled("div", {
  display: "grid",

  /* 2.2rem(35.2px) @ 20rem(320px) increasing to 5.5rem(88px) @ 160rem(2560px) */
  padding: "clamp(2.2rem, calc(2.2rem + ((1vw - 0.2rem) * 2.3571)), 5.5rem) 0",

  /* Safari resize fix */
  minHeight: "0vw",

  gridRowGap: "2.75rem",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "auto",
  gridTemplateAreas: '"about"\n      "contact"\n      "services"',

  "@media (min-width: 1024px)": {
    paddingLeft: "2.5%",
    paddingRight: "2.5%",
    /* Safari resize fix */
    minHeight: "0vw",
    gridColumnGap: "10%",
    gridRowGap: "0",

    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: '"about services"\n        "contact services"',
  },
});

const GridItem = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gridArea: "about",
  "&.contact": {
    gridArea: "contact",
  },
  "&.services": {
    gridArea: "services",
  },
});

const Heading = styled("div", {
  fontWeight: "$600",
  letterSpacing: "calc(2rem / 1000)",
  color: "var(--color-646464)",
  width: "30%",

  /* 0.75rem(12px) @ 20rem(320px) increasing to 1.625rem(26px) @ 160rem(2560px) */
  fontSize:
    "clamp(0.75rem, calc(0.75rem + ((1vw - 0.2rem) * 0.625)), 1.625rem)",
  lineHeight: "133.33%",

  /* Safari resize fix */
  minHeight: "0vw",
});

const UnorderedList = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: "1",

  ul: {
    "> li, > li > p > a": {
      fontWeight: "var(--font-weight-normal)",
      letterSpacing: "var(--character-spacing-0)",
      color: "var(--color-646464)",

      width: "100%",

      /* 0.6875rem(11px) @ 20rem(320px) increasing to 1.125rem(18px) @ 160rem(2560px) */
      fontSize:
        "clamp(0.6875rem, calc(0.6875rem + ((1vw - 0.2rem) * 0.3125)), 1.125rem)",

      /* 1rem(16px) @ 20rem(320px) increasing to 1.375rem(22px) @ 160rem(2560px) */
      lineHeight:
        "clamp(1rem, calc(1rem + ((1vw - 0.2rem) * 0.2679)), 1.375rem)",

      /* Safari resize fix */
      minHeight: "0vw",
    },
  },
});
