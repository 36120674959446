import { InnerContainer } from "../layouts/InnerContainer";

import Instagram from "../Instagram";
import LinkedIn from "../LinkedIn";

import { styled } from "@theme";

export function Footer() {
  return (
    <SemanticFooter>
      <InnerContainer>
        <Row>
          <CopyrightMessage>
            © Tone Studio 2014–{new Date().getFullYear()}
          </CopyrightMessage>

          <SocialLinksContainer>
            <SocialLinksHeading>Follow us on</SocialLinksHeading>
            <a
              href="https://www.instagram.com/_tonestudio/"
              target="_blank"
              aria-label="See Tone studio at Instagram"
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.linkedin.com/company/tonestudio-designforabetterworld"
              target="_blank"
              aria-label="See Tone studio at Linkedin"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </SocialLinksContainer>
        </Row>
      </InnerContainer>
    </SemanticFooter>
  );
}

const SemanticFooter = styled("footer", {
  display: "flex",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: "black",
  height: "var(--footer-height)",

  /* Safari resize fix */
  minHeight: "0vw",
});

const Row = styled("div", {
  display: "flex",
  height: "100%",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
});

const CopyrightMessage = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "$300",
  letterSpacing: "var(--character-spacing-0)",
  color: "var(--color-ffffff)",

  /* 0.5625rem(9px) @ 20rem(320px) increasing to 1.125rem(18px) @ 160rem(2560px) */
  fontSize: `clamp(
      0.5625rem,
      calc(0.5625rem + ((1vw - 0.2rem) * 0.4018)),
      1.125rem
    )`,

  paddingTop: "4px",

  /* Safari resize fix */
  minHeight: "0vw",
});

const SocialLinksContainer = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItem: "center",

  /* 0.9375rem(15px) @ 20rem(320px) increasing to 1.6875rem(27px) @ 160rem(2560px) */
  fontSize: `clamp(
      0.9375rem,
      calc(0.9375rem + ((1vw - 0.2rem) * 0.5357)),
      1.6875rem
    )`,

  /* Safari resize fix */
  minHeight: "0vw",

  /* 0.1875rem(3px) @ 20rem(320px) increasing to 0.375rem(6px) @ 160rem(2560px) */
  paddingTop: `clamp(
      0.1875rem,
      calc(0.1875rem + ((1vw - 0.2rem) * 0.1339)),
      0.375rem
    )`,

  a: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",

    /* 0.1875rem(3px) @ 20rem(320px) increasing to 0.375rem(6px) @ 160rem(2560px) */
    paddingBottom: `clamp(
            0.1875rem,
            calc(0.1875rem + ((1vw - 0.2rem) * 0.1339)),
            0.375rem
          )`,
  },
});

const SocialLinksHeading = styled("div", {
  letterSpacing: "var(--character-spacing-0)",
  color: "var(--color-ffffff)",
  paddingLeft: "20px",
  display: "flex",
  alignItems: "center",

  /* 0.75rem(12px) @ 20rem(320px) increasing to 1.5rem(24px) @ 160rem(2560px) */
  fontSize: `clamp(
      0.75rem,
      calc(0.75rem + ((1vw - 0.2rem) * 0.5357)),
      1.5rem
    )`,

  lineHeight: `clamp(
      0.75rem,
      calc(0.75rem + ((1vw - 0.2rem) * 0.5357)),
      1.5rem
    )`,

  /* Safari resize fix */
  minHeight: "0vw",
});
