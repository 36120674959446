import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
     width="0.942em"
      height="1em"
      
       viewBox="0 0 15.067 14.399" {...props}>
      <g data-name="Group 36">
        <path
          data-name="Path 32"
          d="M8.443 6.018c.19-.224.359-.442.548-.64a2.923 2.923 0 011.79-.88 4.085 4.085 0 011.981.2 3.17 3.17 0 011.713 1.486 5.329 5.329 0 01.588 2.619c.006 1.826 0 3.653 0 5.479 0 .033 0 .065-.006.1h-3.223v-5.1a3.732 3.732 0 00-.175-1.264 1.457 1.457 0 00-1.579-1.01 1.761 1.761 0 00-1.559 1.274 2.858 2.858 0 00-.077.781c-.006 1.715 0 3.431 0 5.146v.17h-3.22v-9.7H8.303c.115 0 .146.032.144.145-.007.353 0 .707 0 1.061z"
          fill="#FFFFFF"
        />
        <path
          data-name="Path 33"
          d="M3.417 4.686v9.71H.342c-.117 0-.143-.035-.143-.146V4.826c0-.113.028-.146.143-.146.983.005 1.966 0 2.949 0z"
          fill="#FFFFFF"
        />
        <path
          data-name="Path 34"
          d="M1.805 3.363a1.73 1.73 0 01-1.531-.765 1.645 1.645 0 01.91-2.49 1.969 1.969 0 011.771.233 1.575 1.575 0 01.664 1.576 1.516 1.516 0 01-1.054 1.309 3.52 3.52 0 01-.761.137"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
